import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { GetIbeTeamVolumeReport } from "../../services/additonalModules/additionalFeature";
import { exportToExcelNew } from "../../utils/tableExports";
const IbeTeamVolumeReport = () => {
    const { t } = useTranslation();
    const headers = [
        { label: "IBE #", filter: "ibe", filterable: true },
        { label: "IBE NAME", filter: "ibe_name", filterable: true },
        { label: "ENTRY LEVEL", filter: "entry_level", filterable: true },
        { label: "PLACEMENT", filter: "placement", filterable: true },
        { label: "BINARY LEVEL", filter: "binary_level", filterable: true },
        { label: "SPONSOR IBE", filter: "sponsor_ibe", filterable: true },
        { label: "SPONSOR NAME", filter: "sponsor_name", filterable: true },
        { label: "RANK", filter: "rank", filterable: true },
        { label: "CITY", filter: "city", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        {
            label: "ORD DATE",
            filter: "ord_date",
            filterable: true,
            type: "date",
        },
        { label: "ORD TYPE", filter: "ord_type", filterable: true },
        { label: "SV $", filter: "sv", filterable: true },
        { label: "LETSV", filter: "letsv", filterable: true },
        { label: "RITSV", filter: "ritsv", filterable: true },
        { label: "CFVL", filter: "cfvl", filterable: true },
        { label: "CFVR", filter: "cfvr", filterable: true },
        { label: "LFTSV", filter: "lftsv", filterable: true },
        { label: "RFTSV", filter: "RFTSV", filterable: true },
        { label: "QCFVL", filter: "qcfvl", filterable: true },
        { label: "QCFVR", filter: "qcfvr", filterable: true },
        { label: "SV TYPE", filter: "sv_type", filterable: true },
    ];

    const startPage = 1;
    const itemsPerPage = 25;
    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { data } = GetIbeTeamVolumeReport({
        filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
        itemsPerPage,
    });

    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setApiCallStatus(true)
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
        setApiCallStatus(true)
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const exportFunc = () => {
        exportToExcelNew(data?.data, headers, "ibe-order-report");
    };
    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }
    return (
        <>
            <div className="report-Container">
                <div className="report-header"> IBE TEAM VOLUME REPORT</div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={data?.data}
                            filters={filters}
                            firstElementSticky={true}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {data && data?.data?.length !== 0 && (
                            <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                                exportFunc={exportFunc}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeTeamVolumeReport;
