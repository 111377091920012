import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CheckoutPackage from "./Checkout";
import CheckoutAddress from "../../components/shopping/CheckoutAddress";
import CheckoutOrderSummary from "../../components/shopping/CheckoutOrderSummary";
import CheckoutPayment from "./CheckoutPayment";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { array } from "yup";
import RegisterTermsAndConditions from "./TermsAndConditions";

const IbeCheckoutLayout = ({
    setValue,
    formValues,
    handleFormValueChange,
    setActiveStepPr,
    products,
    activeCurrencies,
    fsvCriteria,
    cartData,
    setCartData,
    setTotalAmount,
    setTotalPv,
    totalAmount,
    totalPv,
    registerFields,
    gst = 0,
    handleGstCalc,
}) => {
    const [activeStep, setActiveStep] = useState(1);
    // const [cartData, setCartData] = useState(formValues?.cartData);
    // const [totalAmount, setTotalAmount] = useState(formValues?.totalAmount);
    // const [totalPv, setTotalPv] = useState(formValues?.totalPv);

    // ----------------------------------- API -------------------------------------
    // const registerMutation = ApiHook.CallRegisterUser();
    const replicaRegisterMutation = ApiHook.CallReplicaRegisterPost();

    const conversionFactor =
        (formValues?.country).toLowerCase() === "india"
            ? {
                  symbolLeft: activeCurrencies["INR"]?.symbol,
                  value: activeCurrencies["INR"]?.value,
              }
            : {
                  symbolLeft: activeCurrencies["BDT"]?.symbol,
                  value: activeCurrencies["BDT"]?.value,
              };

    const initiatePayment = async ({ payuDatas: datas, payuBaseUrl: url }) => {
        try {
            const form = document.createElement("form");
            form.action = url;
            form.method = "POST";

            for (let [key, value] of Object.entries(datas)) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error("Error initiating payment:", error);
        }
    };

    const handleNext = async () => {
        if (activeStep === 1) {
            const nextStep = activeStep + 1;
            animateStepTransition(nextStep);
            setActiveStep(nextStep);
        } else {
            if ((formValues?.country).toLowerCase() === "india") {
                handleFormValueChange(9, "paymentType");
                const totalAmountCumulated = totalAmount * (1 + gst);
                replicaRegisterMutation.mutate(
                    {
                        ...formValues,
                        cartData,
                        totalAmount: totalAmountCumulated,
                        totalPv,
                        referralId: registerFields?.sponsorData?.id,
                        paymentType: 9,
                    },
                    {
                        onSuccess: async (res) => {
                            if (res.status) {
                                await initiatePayment(res.data);
                            }
                        },
                    }
                );
            } else {
                const nextStep = activeStep + 1;
                animateStepTransition(nextStep);
                setActiveStep(nextStep);
            }
        }
    };
    const handleSubmit = async () => {
        const totalAmountCumulated = totalAmount * (1 + gst);

        replicaRegisterMutation.mutate(
            {
                ...formValues,
                cartData,
                totalAmount: totalAmountCumulated,
                totalPv,
                referralId: registerFields?.sponsorData?.id,
            },
            {
                onSuccess: (res) => {},
            }
        );
    };

    const handleBack = () => {
        const prevStep = activeStep - 1;
        animateStepTransition(prevStep);
        setActiveStep(prevStep);
    };

    const animateStepTransition = () => {
        const containerElement = document.querySelector("#animation");
        // Set initial position of the next step
        anime.set(containerElement, { opacity: 0 });
        // Animate the next step in
        anime({
            targets: containerElement,
            opacity: [0, 1],
            duration: 1100,
            easing: "easeInOutQuad",
        });
    };
    return (
        <>
            {activeStep !== 2 && (
                <div className="common-div-header gta-b">
                    {activeStep === 1 ? "CART CHECKOUT" : "CART PAYMENT"}
                </div>
            )}
            {activeStep !== 2 && (
                <div className="checkout_contant_area_section">
                    <div className="checkout_contant_cart_sec">
                        <div id="animation">
                            {activeStep === 1 && (
                                <CheckoutPackage
                                    totalAmount={totalAmount}
                                    handleNext={handleNext}
                                    handleFormValueChange={
                                        handleFormValueChange
                                    }
                                    setActiveStepPr={setActiveStepPr}
                                    products={products}
                                    setCartData={setCartData}
                                    totalPv={totalPv}
                                    setTotalAmount={setTotalAmount}
                                    setTotalPv={setTotalPv}
                                    conversionFactor={conversionFactor}
                                    cartData={cartData}
                                    fsvCriteria={fsvCriteria}
                                    setValue={setValue}
                                    gst={gst}
                                    handleGstCalc={handleGstCalc}
                                />
                            )}

                            {activeStep === 3 && (
                                <CheckoutPayment
                                    formValues={formValues}
                                    handleFormValueChange={
                                        handleFormValueChange
                                    }
                                    setActiveStep={setActiveStep}
                                    registerFields={registerFields}
                                    totalAmount={totalAmount}
                                    conversionFactor={conversionFactor}
                                    handleSubmit={handleSubmit}
                                    gst={gst}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
            {activeStep === 2 && (
                <RegisterTermsAndConditions
                    data={registerFields?.termsAndCondition?.termsAndConditions}
                    handleFormValueChange={handleFormValueChange}
                    formValues={formValues}
                    handleNext={handleNext}
                />
            )}
        </>
    );
};

export default IbeCheckoutLayout;
