import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CheckoutPackage from "./CheckoutPackage";
import CheckoutAddress from "../../components/shopping/CheckoutAddress";
import CheckoutOrderSummary from "../../components/shopping/CheckoutOrderSummary";
import CheckoutPayment from "./CheckoutPayment";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const UpgradeCheckOut = ({
    setActiveStepPr,
    products,
    cartData,
    setCartData,
    setTotalAmount,
    setTotalPv,
    totalAmount,
    totalPv,
    conversionFactor,
    userCountry,
    userData,
    upfsvCriteria,
    handleGstCalc,
    gst,
}) => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(1);
    const [transPass, setTransPass] = useState("");
    const [paymentMethod, setPaymentMethod] = useState(3);

    // const [cartData, setCartData] = useState(formValues?.cartData);
    // const [totalAmount, setTotalAmount] = useState(formValues?.totalAmount);
    // const [totalPv, setTotalPv] = useState(formValues?.totalPv);

    // ----------------------------------- API -------------------------------------
    const upgradeSubscriptionMutation = ApiHook.CallUpgradeSubscription();

    const initiatePayment = async ({ payuDatas: datas, payuBaseUrl: url }) => {
        try {
            const form = document.createElement("form");
            form.action = url;
            form.method = "POST";

            for (let [key, value] of Object.entries(datas)) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error("Error initiating payment:", error);
        }
    };

    const handleNext = async () => {
        if (userCountry.toLowerCase() === "india") {
            upgradeSubscriptionMutation.mutate(
                {
                    cartData,
                    totalAmount: totalAmount * (1 + gst),
                    totalPv,
                    paymentMethod,
                    transactionPassword: transPass,
                    paymentMethod: 9,
                },
                {
                    onSuccess: async (res) => {
                        if (res.status) {
                            if (res?.data?.payuBaseUrl) {
                                await initiatePayment(res.data);
                            } else {
                                setCartData({});
                                setActiveStepPr(1);
                                setTotalAmount(0);
                                setTotalPv(0);
                                setPaymentMethod(3);
                                setTransPass("");
                            }
                        }
                    },
                }
            );
        } else {
            setPaymentMethod(2);
            const nextStep = activeStep + 1;
            animateStepTransition(nextStep);
            setActiveStep(nextStep);
        }
    };
    const handleSubmit = async () => {
        upgradeSubscriptionMutation.mutate(
            {
                cartData,
                totalAmount: totalAmount * (1 + gst),
                totalPv,
                paymentMethod,
                transactionPassword: transPass,
            },
            {
                onSuccess: (res) => {
                    if (res.status) {
                        setCartData({});
                        setActiveStepPr(1);
                        setTotalAmount(0);
                        setTotalPv(0);
                        setPaymentMethod(3);
                        setTransPass("");
                    }
                },
            }
        );
    };

    const handleBack = () => {
        const prevStep = activeStep - 1;
        animateStepTransition(prevStep);
        setActiveStep(prevStep);
    };

    const animateStepTransition = () => {
        const containerElement = document.querySelector("#animation");
        // Set initial position of the next step
        anime.set(containerElement, { opacity: 0 });
        // Animate the next step in
        anime({
            targets: containerElement,
            opacity: [0, 1],
            duration: 1100,
            easing: "easeInOutQuad",
        });
    };
    return (
        <>
            <div className="common-div-header gta-b">
                {activeStep === 1 ? "CART CHECKOUT" : "CART PAYMENT"}
            </div>
            <div className="checkout_contant_area_section">
                <div className="checkout_contant_cart_sec">
                    <div id="animation">
                        {activeStep === 1 && (
                            <CheckoutPackage
                                totalAmount={totalAmount}
                                handleNext={handleNext}
                                setActiveStepPr={setActiveStepPr}
                                products={products}
                                setCartData={setCartData}
                                totalPv={totalPv}
                                setTotalAmount={setTotalAmount}
                                setTotalPv={setTotalPv}
                                conversionFactor={conversionFactor}
                                cartData={cartData}
                                upfsvCriteria={upfsvCriteria}
                                handleGstCalc={handleGstCalc}
                                gst={gst}
                            />
                        )}
                        {activeStep === 2 && (
                            <CheckoutPayment
                                setActiveStep={setActiveStep}
                                userData={userData}
                                totalAmount={totalAmount}
                                conversionFactor={conversionFactor}
                                handleSubmit={handleSubmit}
                                transPass={transPass}
                                setTransPass={setTransPass}
                                gst={gst}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpgradeCheckOut;
