import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { ApiHook } from "../../hooks/apiHook";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";

const PurchaseInvoice = ({
    invoiceNoProps = null,
    typeProps = null,
    customStyle = false,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const tableRef = useRef();
    const invoiceNo = !invoiceNoProps ? params.id : invoiceNoProps;
    const type = !typeProps ? params.type : typeProps;
    const [conversionFactor, setConversionFactor] = useState();

    // const conversionFactor = useSelector(
    //   (state) => state?.user
    // );

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });

    const handleBack = () => {
        navigate("/repurchase-report");
    };

    //---------------------------------- API ------------------------------------
    const invoice = ApiHook.CallRepurchaseInvoice(invoiceNo, type);

    useEffect(() => {
        setConversionFactor(
            invoice?.data?.userCountry?.toLowerCase() === "india"
                ? {
                      symbolLeft:
                          invoice?.data?.activeCurrencies["INR"]?.symbol,
                      value: invoice?.data?.activeCurrencies["INR"]?.value,
                  }
                : invoice?.data?.userCountry?.toLowerCase() === "bangladesh"
                ? {
                      symbolLeft:
                          invoice?.data?.activeCurrencies["BDT"]?.symbol,
                      value: invoice?.data?.activeCurrencies["BDT"]?.value,
                  }
                : {
                      symbolLeft:
                          invoice?.data?.activeCurrencies["USD"]?.symbol,
                      value: invoice?.data?.activeCurrencies["USD"]?.value,
                  }
        );
    }, [invoice?.data]);
    return (
        <div style={{ height: "100vh" }}>
            {/* <div className="page_head_top">{t("purchase_invoice")}</div> */}
            <div className="container-bg-design" style={{ height: "100vh" }}>
                <div className={customStyle ? "" : "col-md-8 m-auto"}>
                    <div
                        className="purchase_invoice"
                        ref={tableRef}
                        style={customStyle ? { marginTop: "0px" } : {}}
                    >
                        <div
                            className="alertNotification"
                            style={{
                                width: "100%",
                                border: "1px solid rgb(153 88 53)",
                                background: "rgba(153, 88, 53, 0.4)",
                                display: "inline-block",
                                color: "rgb(153 88 53)",
                                padding: "8px 15px 8px 15px",
                                // borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                            }}
                        >
                            <p style={{ margin: "0" }}>
                                {"Order Completed Successfully"}
                                {invoice.data?.orderStatus === "Pending" &&
                                    " but is still awaiting final confirmation."}
                            </p>
                        </div>
                        <h3 style={{ margin: "20px" }}>Order Details</h3>
                        <table className="striped">
                            <tbody>
                                <tr className="font-weight-bold mb-1">
                                    <td>{t("Invoice No")}</td>
                                    <td>{invoice.data?.invoiceNo}</td>
                                </tr>
                                {type !== "repurchase" && (
                                    <tr className="font-weight-bold mb-1">
                                        <td>{t("entry_level")}</td>

                                        <td>
                                            {" "}
                                            {invoice.data?.items[0].package}
                                        </td>
                                    </tr>
                                )}
                                <tr className="font-weight-bold mb-1">
                                    <td>{t("date")}</td>
                                    <td>{invoice.data?.date}</td>
                                </tr>
                                <tr className="font-weight-bold mb-1">
                                    <td>{t("paymentMethod")}</td>
                                    <td>
                                        {
                                            invoice.data?.paymentDetails
                                                ?.paymentMethod
                                        }
                                    </td>
                                </tr>

                                <tr className="font-weight-bold mb-1">
                                    <td>{t("totalAmount")}</td>

                                    <td>
                                        {`${
                                            conversionFactor?.symbolLeft
                                        } ${CustomCurrencyConverter(
                                            invoice.data?.grandTotal,
                                            conversionFactor?.value
                                        )}`}
                                    </td>
                                </tr>

                                <tr className="font-weight-bold mb-1">
                                    <td>{t("status")}</td>

                                    <td>{invoice.data?.orderStatus}</td>
                                </tr>
                            </tbody>
                        </table>

                        {(type === "repurchase" || customStyle) && (
                            <>
                                <hr className="my-1" />
                                <div className="row p-1">
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead
                                                style={{
                                                    backgroundColor:
                                                        "rgb(153 88 53)",
                                                    color: "white",
                                                }}
                                            >
                                                <tr>
                                                    <th className="text-uppercase small font-weight-bold">
                                                        #
                                                    </th>
                                                    <th className="text-uppercase small font-weight-bold">
                                                        {t("package")}
                                                    </th>
                                                    <th className="text-uppercase small font-weight-bold">
                                                        {t("quantity")}
                                                    </th>
                                                    <th className="text-uppercase small font-weight-bold text-end">
                                                        {t("total")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                style={{
                                                    borderColor: "aliceblue",
                                                }}
                                            >
                                                {invoice.data?.items?.map(
                                                    (item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {item.package}
                                                            </td>
                                                            <td>
                                                                {item.quantity}
                                                            </td>
                                                            <td className="text-end">
                                                                {`${
                                                                    conversionFactor?.symbolLeft
                                                                } ${CustomCurrencyConverter(
                                                                    item.amount,
                                                                    conversionFactor?.value
                                                                )}`}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseInvoice;
