import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { useState } from "react";
import EventList from "./EventList";
const EventPurchase = () => {
  const { t } = useTranslation();
  const [totalAmount, setTotalAmount] = useState(0);
  const [conversionFactor, setConversionFactor] = useState();
  const eventList = ApiHook.CallGetEvents();
  const [events, setEvent] = useState([]);

  useEffect(() => {
    setConversionFactor(
      eventList?.userCountry.toLowerCase() === "india"
        ? {
            symbolLeft: eventList?.activeCurrencies["INR"]?.symbol,
            value: eventList?.activeCurrencies["INR"]?.value,
          }
        : eventList?.userCountry.toLowerCase() === "bangladesh"
        ? {
            symbolLeft: eventList?.activeCurrencies["BDT"]?.symbol,
            value: eventList?.activeCurrencies["BDT"]?.value,
          }
        : {
            symbolLeft: eventList?.activeCurrencies["USD"]?.symbol,
            value: eventList?.activeCurrencies["USD"]?.value,
          }
    );
    if (eventList) {
      setEvent(eventList.evntDetails.map((i) => i.eventName));
    }
  }, [eventList]);

  return (
    <div className="container-bg-design common-div-container">
      <div className="common-div-header gta-b">EVENT PURCHASE</div>
      <div
        className="container-bg-design-container-flex"
        style={{ height: "100vh" }}
      >
        <div className="container-bg-design repurchase-cart-in-container">
          <div className="repurchase-cart-in-list-flex">
            <div className="repurchase-cart-in-list">
              <EventList
                setTotalAmount={setTotalAmount}
                totalAmount={totalAmount}
                userCountry={eventList?.userCountry}
                userData={eventList?.userData}
                conversionFactor={conversionFactor}
                balance={eventList?.tainingWalletBalance ?? 0}
                eventList={eventList?.evntDetails ?? []}
                events={events}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPurchase;
