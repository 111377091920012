import React, { useState } from "react";
import CommonInput from "../Common/CommonInputComponent";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import {
    isANumber,
    isAlphanumeric,
    isMaxLength,
} from "../../utils/validationHealpers";

const IbeVerificationFormIn = ({
    register,
    setError,
    errors,
    formValues,
    registerFields,
    handleFormValueChange,
    setActiveStep,
}) => {
    const checkPanCheck = ApiHook.CallRegisterPanCheck();
    const checkMobileOtpSend = ApiHook.CallMobileOtp();
    const checkMobileOtpVerify = ApiHook.CallMobileOtpVerify();
    const checkBankOtpSend = ApiHook.CallBankOtp();
    const checkBankOtpVerify = ApiHook.CallBankOtpVerify();

    const [mobileVerifyStatus, setMobileVerifyStatus] = useState("notVerified");
    const [panVerifyStatus, setPanVerifyStatus] = useState(false);
    const [bankVerifyStatus, setBankVerifyStatus] = useState("notVerified");
    const [termsCheck, setTermsCheck] = useState(false);
    const [otpUniqueId, setotpUniqueId] = useState("");
    const [checkBoxes, setCheckBoxesChecked] = useState({
        verifyMobile: false,
    });

    const backgroundColor = "#e5f0f2";

    const handleMobileNoGetCode = () => {
        if (
            isValidPhoneNumber(
                `+${formValues?.phoneCode} ${formValues?.mobile}`
            )
        ) {
            checkMobileOtpSend.mutate(
                {
                    mobile: formValues?.mobile,
                    phoneCode: formValues?.phoneCode,
                },
                {
                    onSuccess: (res) => {
                        if (res?.status) {
                            setMobileVerifyStatus("otp");
                            setotpUniqueId(res?.data?.uniqueId);
                        } else {
                            setError("mobile", {
                                message: "Invalid Mobile Number",
                            });
                        }
                    },
                }
            );
        } else {
            setError("mobile", { message: "Invalid Mobile Number" });
        }
    };

    const handleMobileNoVerify = () => {
        if (checkBoxes?.verifyMobile) {
            checkMobileOtpVerify.mutate(
                {
                    mobileOtp: formValues?.mobileOtp,
                    otpUniqueId,
                    mobile: formValues?.mobile,
                    phoneCode: formValues?.phoneCode,
                },
                {
                    onSuccess: (res) => {
                        if (res.status) {
                            setMobileVerifyStatus("verified");
                            setError("mobileOtp", { message: "" });
                        } else {
                            setError("mobileOtp", { message: "Invalid Otp" });
                        }
                    },
                }
            );
        } else {
            setTermsCheck(true);
        }
    };

    const handleCheckedStatusChange = (e, type) => {
        setCheckBoxesChecked((state) => ({
            ...state,
            verifyMobile: e.target.checked,
        }));
        setTermsCheck(false);
    };

    const handlePanVerify = () => {
        checkPanCheck.mutate(
            { pan: formValues?.pan },
            {
                onSuccess: (res) => {
                    if (res.status) {
                        setError("pan", { message: "" });
                        setPanVerifyStatus(true);
                    } else {
                        setError("pan", { message: "Invalid Pan Number" });
                    }
                },
            }
        );
    };
    const handleGetBankOtp = () => {
        if (!formValues?.accountNo) {
            setError("accountNo", {
                message: "Invalid Account Number",
            });
        } else if (!formValues?.ifsc) {
            setError("ifsc", {
                message: "Invalid IFSC Code",
            });
        } else {
            checkBankOtpSend.mutate(
                {
                    accountNo: formValues?.accountNo,
                    ifsc: formValues?.ifsc,
                },
                {
                    onSuccess: (res) => {
                        if (res?.status) {
                            // setBankVerifyStatus("otp");
                            setError("accountNo", {
                                message: "",
                            });
                            setError("ifsc", {
                                message: "",
                            });
                            setBankVerifyStatus("verified");
                        } else {
                            // setError("accountNo", {
                            //     message: "Invalid Account Number",
                            // });
                            setError("ifsc", {
                                message: "Invalid Bank details",
                            });
                        }
                    },
                }
            );
        }
    };

    const handleVerifyBankOtp = () => {
        if (formValues?.bankVerifyOtp) {
            checkBankOtpVerify.mutate(
                {
                    bankVerifyOtp: formValues?.bankVerifyOtp,
                },
                {
                    onSuccess: (res) => {
                        if (res?.status) {
                            setBankVerifyStatus("verified");
                        } else {
                            setError("bankVerifyOtp", {
                                message: "Invalid OTP",
                            });
                        }
                    },
                }
            );
        } else {
            setError("bankVerifyOtp", {
                message: "Invalid OTP",
            });
        }
    };

    const phoneCodeList = (data) => {
        return data.map((item) => item?.phoneCode);
    };
    return (
        <>
            <div className="">
                <div className="common-div-header gta-b">
                    BRAND EXECUTIVE VERIFICATION FORM INDIA
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="register-verification-container">
                        {/* <CommonInput
                            label="MOBILE NUMBER"
                            backgroundColor="#e5f0f2"
                        /> */}

                        <CommonInput
                            label="mobile Number"
                            type="mobile"
                            placeholder={"Mobile Number"}
                            onChange={(e) => {
                                if (
                                    isMaxLength(e.target.value, 10) &&
                                    isANumber(e.target.value)
                                ) {
                                    handleFormValueChange(
                                        e.target.value,
                                        "mobile"
                                    );
                                }
                            }}
                            options={phoneCodeList(
                                registerFields?.contactInformation?.contactField?.filter(
                                    (item) => item?.code === "country"
                                )[0]?.options ?? [""]
                            )}
                            // phoneCodeChange={(e) => {
                            //     handleFormValueChange(
                            //         e.target.value,
                            //         "phoneCode"
                            //     );
                            // }}
                            value={formValues?.mobile}
                            phoneCode={formValues?.phoneCode}
                            backgroundColor={backgroundColor}
                            formRegister={register("mobile", {
                                required: "mobile is required",
                            })}
                            phoneCodeDisabled={true}
                        />
                        {errors?.mobile?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.mobile?.message}
                            </span>
                        )}
                        <button
                            className="common-button"
                            onClick={handleMobileNoGetCode}
                            disabled={mobileVerifyStatus === "verified"}
                        >
                            GET MOBILE VERIFICATION CODE
                        </button>
                        {mobileVerifyStatus !== "notVerified" && (
                            <>
                                <CommonInput
                                    label={"OTP"}
                                    placeholder={"Otp"}
                                    value={formValues?.mobileOtp}
                                    onChange={(e) => {
                                        handleFormValueChange(
                                            e.target.value,
                                            "mobileOtp"
                                        );
                                    }}
                                    backgroundColor={"#e5f0f2"}
                                    disabled={mobileVerifyStatus === "verified"}
                                />
                                {errors?.mobileOtp?.message !== "" && (
                                    <span className="error-message-validator">
                                        {errors?.mobileOtp?.message}
                                    </span>
                                )}

                                <div className="register-checkbox-container">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            handleCheckedStatusChange(
                                                e,
                                                "verifyMobile"
                                            );
                                        }}
                                        style={
                                            termsCheck
                                                ? {
                                                      outline: "red",
                                                  }
                                                : {}
                                        }
                                    />
                                    <span
                                        style={
                                            termsCheck
                                                ? {
                                                      color: "red",
                                                  }
                                                : {}
                                        }
                                    >
                                        By checking this box, I acknowledge that
                                        i agree to recieve updates through
                                        whatsapp, Sms and email
                                    </span>
                                </div>
                                <button
                                    className="common-button"
                                    onClick={handleMobileNoVerify}
                                    disabled={mobileVerifyStatus === "verified"}
                                >
                                    VERIFY MOBILE NUMBER
                                </button>
                            </>
                        )}
                        {mobileVerifyStatus === "verified" && (
                            <>
                                <CommonInput
                                    label="Pan Number"
                                    placeholder={"Pan Number"}
                                    backgroundColor={backgroundColor}
                                    value={formValues?.pan}
                                    onChange={(e) => {
                                        if (
                                            isAlphanumeric(e.target.value) &&
                                            isMaxLength(e.target.value, 13)
                                        ) {
                                            handleFormValueChange(
                                                e.target.value,
                                                "pan"
                                            );
                                        }
                                    }}
                                    disabled={panVerifyStatus}
                                />
                                {errors?.pan?.message !== "" && (
                                    <span className="error-message-validator">
                                        {errors?.pan?.message}
                                    </span>
                                )}

                                <button
                                    className="common-button"
                                    onClick={handlePanVerify}
                                    disabled={panVerifyStatus}
                                >
                                    VERIFY PAN NUMBER
                                </button>
                            </>
                        )}
                        {panVerifyStatus && (
                            <>
                                <CommonInput
                                    label="bank account Number"
                                    placeholder="Bank Account Number"
                                    backgroundColor={backgroundColor}
                                    value={formValues?.accountNo}
                                    onChange={(e) => {
                                        if (
                                            isANumber(e.target.value) &&
                                            isMaxLength(e.target.value, 22)
                                        ) {
                                            handleFormValueChange(
                                                e.target.value,
                                                "accountNo"
                                            );
                                        }
                                    }}
                                />

                                <CommonInput
                                    label="IFSC CODE"
                                    placeholder="IFSC Code"
                                    backgroundColor={backgroundColor}
                                    value={formValues?.ifsc}
                                    onChange={(e) => {
                                        if (isAlphanumeric(e.target.value)) {
                                            handleFormValueChange(
                                                e.target.value,
                                                "ifsc"
                                            );
                                        }
                                    }}
                                />
                                {errors?.ifsc?.message !== "" && (
                                    <span className="error-message-validator">
                                        {errors?.ifsc?.message}
                                    </span>
                                )}
                                {/* {bankVerifyStatus === "notVerified" && ( */}
                                <>
                                    <button
                                        className="common-button"
                                        onClick={() => {
                                            handleGetBankOtp();
                                        }}
                                    >
                                        {/* GET OTP */}
                                        VERIFY BANK ACCOUNT NUMBER
                                    </button>
                                </>
                                {/* )} */}

                                {/* {bankVerifyStatus !== "notVerified" && (
                                    <>
                                        <CommonInput
                                            label="OTP"
                                            placeholder="Enter Otp"
                                            backgroundColor={backgroundColor}
                                            value={formValues?.bankVerifyOtp}
                                            onChange={(e) => {
                                                handleFormValueChange(
                                                    e.target.value,
                                                    "bankVerifyOtp"
                                                );
                                            }}
                                        />

                                        <button
                                            className="common-button"
                                            onClick={() => {
                                                handleVerifyBankOtp();
                                            }}
                                        >
                                            VERIFY BANK ACCOUNT NUMBER
                                        </button>
                                    </>
                                )} */}
                                {bankVerifyStatus === "verified" && (
                                    <>
                                        <button
                                            className="common-button"
                                            onClick={() => {
                                                setActiveStep(
                                                    (state) => state + 1
                                                );
                                            }}
                                        >
                                            PROCEED TO SIGNUP
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeVerificationFormIn;
