
import  {eventService}  from "../../services/event/event";
export const EventPurchaseActions = {
    getEvents: async () => {
    try {
      const response = await eventService.getEvents();
      return response.data.data;
    } catch (error) {
      return error.message;
    }
  },
  eventPurchase: async (data) => {
    try {
      const response = await eventService.eventPurchase(data);
      return response.data
    } catch (error) {
      return error.message
    }
  }
  
};