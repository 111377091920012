import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import CommonInput from "../Common/CommonInputComponent";
import CustomSelectBox from "../Common/CustomSelectBox";
import CustomFileInput from "../Common/CustomFileInput";
import CaptchaImage from "../Common/CapthaImage";

const TicketForm = ({ partials }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [subject, setSubject] = useState("");
  const [category, setCategory] = useState({});
  const [priority, setPriority] = useState({});
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState(new Map());
  const [ticketId, setTicketId] = useState("");
  const [captchaText, setCaptchaText] = useState("");
  const [captchaUser, setCaptchaUser] = useState("");
  const [reloadCaptha, setReloadCaptcha] = useState("");

  const trackId = ApiHook.CallTrackId();
  const ticketMutation = ApiHook.CallCreateTicket();

  const handleSubjectChange = (e) => {
    setSubject(e.target.value)
  }

  const handleCategorySelect = (value) => {
    setCategory(value)
  }

  const onSubmit = () => {
    if(!subject || !Object.keys(category).length || !Object.keys(priority).length) {
      toast.error("Subject category or priority required!");
      return;
    } 
    if(captchaUser !== captchaText) {
      toast.error("Incorrect captcha!");
      setCaptchaUser("");
      setReloadCaptcha(!reloadCaptha);
      return;
    }
    
    let uploadFiles = [];

    for (const [key, value] of files) {
      value.forEach((file) => {
        uploadFiles.push(file);
      });
    }

    let data = {
      ticketId: ticketId,
      subject,
      category: category?.value,
      priority: priority?.value,
      message,
      attachment: uploadFiles
    }
    
    ticketMutation.mutate(data, {
      onSuccess: (res) => {
        if (res.status) {
          toast.success(t(res.data));
          queryClient.invalidateQueries({ queryKey: ["get-trackId"] });
          queryClient.invalidateQueries({ queryKey: ["tickets"] });
          setCategory({});
          setSubject("");
          setFiles(new Map());
          setPriority({});
          setMessage("");
          setCaptchaUser("");
        } else {
          if (res?.data?.code) {
            toast.error(t(res.data?.description));
          }
        }
      },
    });
  };

  useEffect(() => {
    setTicketId(trackId.data);
  }, [trackId.data]);


  return (
    <div style={{ marginBottom: "40px", display: "flex", flexDirection: "column", alignItems: "center",zIndex:"2" }}>
        <CommonInput 
          label="SUBJECT"
          placeholder={"Subject"}
          value={subject}
          onChange={handleSubjectChange} 
          backgroundColor={'#e5f0f2'}
        />
        <label className="custom-label mt-1">CATEGORY</label>
        <CustomSelectBox value={category}  placeHolder={"Select category"} options={partials?.categories || []} onSelect={handleCategorySelect} />

        <label className="custom-label">PRIORITY</label>
        <CustomSelectBox value={priority}  placeHolder={"Select category"} options={partials?.priorities || []} onSelect={(value) => setPriority(value)} />

        <label className="custom-label"> MESSAGE </label>
        <div style={{ position: "relative", margin: "10px 0", width: "100%" }} className="square-border-cut">
          <textarea style={!message ? { resize: "none", padding: "80px 0 0 15px", background:'transparent', border:'none' } : { resize: "none", background:'transparent' }}
              className="form-control no-active-outline ticket-text-area"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
          />
        </div>

        <label style={{ marginBottom: "10px"}} className="custom-label"> SELECT IMAGE </label>
        <CustomFileInput files={files} setFiles={setFiles} id={"attachment"} />

        <label style={{ margin: "10px 0"}} className="custom-label mt-4">Enter Captcha Code </label>
        <div style={{ position: "relative", width: "100%" }} className="square-border-cut captcha-parent">
          <input value={captchaUser} onChange={(e) => setCaptchaUser(e.target.value)} style={{ height: "70px", lineHeight: "5", fontSize: "30px", width: "40%", background:'transparent' }} className="no-active-outline captcha-code" type="text" placeholder="Captcha" />
          <CaptchaImage capchaText={captchaText} setCaptchaText={setCaptchaText} reload={reloadCaptha} />
        </div>

        <button style={{ margin: "20px 0" }} className="button-maxwidth" onClick={onSubmit}>
          <span style={{ fontWeight: 700, color: "white" }}>SUBMIT</span>
        </button>
    </div>
  );
};

export default TicketForm;
