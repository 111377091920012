import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CheckoutPackage from "../../components/shopping/CheckoutPackage";
import CheckoutAddress from "../../components/shopping/CheckoutAddress";
import CheckoutOrderSummary from "../../components/shopping/CheckoutOrderSummary";
import CheckoutPayment from "../../components/shopping/CheckoutPayment";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const CheckoutLayout = () => {
    const params = useParams();

    const [activeStep, setActiveStep] = useState(1);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        register,
        setValue,
        setError,
        trigger,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            product: [],
        },
    });
    const formValues = watch();
    // ----------------------------------- API -------------------------------------
    const items = ApiHook.CallCartItems();
    const removeCartMutation = ApiHook.CallRemoveCartItem();
    const repurchaseMutation = ApiHook.CallPlaceRepurchaseOrder();

    useEffect(() => {
        const list = items.data?.cartList?.map((product) => ({
            name: product.name,
            quantity: product.quantity,
            totalAmount: product.price * product.quantity,
        }));
        setValue("product", list);
        setValue("totalAmount", totalAmount);
    }, [items.data?.cartList]);

    const handleFormValueChange = (value, type, options = {}) => {
        setValue(`${type}`, value, options);
        setError(`${type}`, { message: "" });
    };

    const initiatePayment = async ({ payuDatas: datas, payuBaseUrl: url }) => {
        try {
            const form = document.createElement("form");
            form.action = url;
            form.method = "POST";

            for (let [key, value] of Object.entries(datas)) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error("Error initiating payment:", error);
        }
    };
    const handleNext = async () => {
        let isValid = await trigger();
        // if (activeStep === 2) {
        //     if (!formValues.addressId) {
        //         if (address.data?.length === 0) {
        //             toast.error(t("add_new_address"));
        //         }
        //         isValid = false;
        //     }
        // }
        // if (activeStep === 2) {
        //     const list = items.data?.cartList?.map((product) => ({
        //         name: product.name,
        //         quantity: product.quantity,
        //         totalAmount: product.price * product.quantity,
        //     }));
        //     setValue("product", list);
        //     setValue("totalAmount", totalAmount);
        // }
        if (isValid) {
            formValues.repurchaseType = params?.type ?? "PCV";

            if (items.data?.userCountry === "India") {
                formValues.paymentType = 9;
                repurchaseMutation.mutate(
                    { ...formValues, repurchaseType: params?.type ?? "PCV" },
                    {
                        onSuccess: async (res) => {
                            if (res?.data.status) {
                                if (res?.data?.data?.payuBaseUrl) {
                                    await initiatePayment(res?.data?.data);
                                } else {
                                    toast.success(t(res?.data?.data?.message));
                                    navigate("/shopping");
                                }
                            } else {
                                toast.error(res?.data?.description);
                            }
                        },
                    }
                );
            } else {
                formValues.paymentType = 2;
                const nextStep = activeStep + 1;
                animateStepTransition(nextStep);
                setActiveStep(nextStep);
            }
        }
    };

    const handleSubmit = () => {
        repurchaseMutation.mutate(
            { ...formValues, repurchaseType: params?.type ?? "PCV" },
            {
                onSuccess: (res) => {
                    if (res?.data.status) {
                        toast.success(t(res?.data?.data?.message));
                        navigate("/shopping");
                    } else {
                        toast.error(res?.data?.description);
                    }
                },
            }
        );
    };
    const handleBack = () => {
        const prevStep = activeStep - 1;
        animateStepTransition(prevStep);
        setActiveStep(prevStep);
    };

    const animateStepTransition = () => {
        const containerElement = document.querySelector("#animation");
        // Set initial position of the next step
        anime.set(containerElement, { opacity: 0 });
        // Animate the next step in
        anime({
            targets: containerElement,
            opacity: [0, 1],
            duration: 1100,
            easing: "easeInOutQuad",
        });
    };

    const handleClearAll = () => {
        removeCartMutation.mutate(
            { packageId: "all" },
            {
                onSuccess: () => {
                    navigate("/shopping");
                },
            }
        );
    };

    const totalAmount = items.data?.cartList?.reduce(
        (total, product) =>
            total + product.price * (items.data?.gst + 1) * product.quantity,
        0
    );

    return (
        <div
            className="container-bg-design-container-flex"
            style={
                activeStep === 4
                    ? { height: "100vh", backgroundColor: "#fff" }
                    : { height: "100vh" }
            }
        >
            <div className="container-bg-design">
                <div className="container">
                    <div className="common-div-header gta-b">CHECKOUT CART</div>
                    <div className="checkout_contant_area_section">
                        {/* {activeStep === 1 && (
                    <div className="checkout_contant_area_head">
                        <h3>
                            <NavLink to={"/shopping"}>
                                <i className="fa fa-angle-left"></i>
                            </NavLink>{" "}
                            {t("package")}
                        </h3>
                        <button
                            className="checkout_contant_clear_btn"
                            onClick={handleClearAll}
                        >
                            {t("clear_all")}
                        </button>
                    </div>
                )} */}
                        {/* {activeStep === 2 && (
                    <div className="checkout_contant_area_head">
                        <h3>
                            <button onClick={handleBack}>
                                <i className="fa fa-angle-left"></i>
                            </button>{" "}
                            {t("contact_information")}
                        </h3>
                        <button
                            type="button"
                            className="checkout_contant_clear_btn"
                            onClick={() => setShow(true)}
                        >
                            {t("add_new")}
                        </button>
                    </div>
                )} */}
                        {/* {activeStep === 3 && (
                    <div className="checkout_contant_area_head">
                        <h3>
                            <button onClick={handleBack}>
                                <i className="fa fa-angle-left"></i>
                            </button>{" "}
                            {t("order_summary")}
                        </h3>
                    </div>
                )} */}
                        {/* {activeStep === 2 && (
                    <div className="checkout_contant_area_head">
                        <h3>
                            <button onClick={handleBack}>
                                <i className="fa fa-angle-left"></i>
                            </button>{" "}
                            {t("payment_order")}
                        </h3>
                    </div>
                )} */}
                        <div className="checkout_contant_cart_sec">
                            {/* <div className="main-content-regsiter">
                        <div className="wizard-form py-4 my-2">
                            <ul
                                id="progressBar"
                                className="progressbar px-lg-5 px-0"
                            >
                                {steps.map((step, index) => (
                                    <li
                                        key={`step-${index + 1}`}
                                        id={`progressList-${index + 1}`}
                                        className={`d-inline-block position-relative text-center float-start progressbar-list w-33 ${
                                            index <= activeStep - 1
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        {step}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div> */}
                            <div id="animation">
                                {activeStep === 1 && items.data && (
                                    <CheckoutPackage
                                        items={items.data?.cartList}
                                        totalAmount={totalAmount}
                                        handleNext={handleNext}
                                        conversionFactor={
                                            items.data?.conversionFactor
                                        }
                                        gst={items?.data?.gst}
                                    />
                                )}
                                {/* {activeStep === 2 && (
                            <CheckoutAddress
                                totalAmount={totalAmount}
                                handleNext={handleNext}
                                show={show}
                                setShow={setShow}
                                address={address?.data}
                                register={register}
                                formValues={formValues}
                                setValue={setValue}
                                errors={errors}
                            />
                        )} */}
                                {/* {activeStep === 3 && (
                            <CheckoutOrderSummary
                                handleNext={handleNext}
                                totalAmount={totalAmount}
                                items={items?.data}
                            />
                        )} */}
                                {activeStep === 2 && (
                                    <CheckoutPayment
                                        formValues={formValues}
                                        handleFormValueChange={
                                            handleFormValueChange
                                        }
                                        setActiveStep={setActiveStep}
                                        walletUsername={
                                            items.data?.walletUsername
                                        }
                                        totalAmount={totalAmount}
                                        conversionFactor={
                                            items.data?.conversionFactor
                                        }
                                        handleSubmit={handleSubmit}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutLayout;
