import React from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const UpgradeCheckoutPackage = ({
    totalAmount,
    handleNext,
    shippingCharge = 0,
    setActiveStepPr,
    setCartData,
    setTotalAmount,
    setTotalPv,
    cartData,
    conversionFactor,
    totalPv,
    upfsvCriteria,
    gst,
    handleGstCalc,
}) => {
    const { t } = useTranslation();
    const handleQuantityChange = (product, type) => {
        const { id: packageId, mrp, upfsv } = product;

        if (type === "plus") {
            setCartData((cart) => ({
                ...cart,
                [packageId]: {
                    ...product,
                    qty: cart[packageId] ? cart[packageId]?.qty + 1 : 1,
                },
            }));
            setTotalAmount((total) => total + Number(mrp));
            setTotalPv((total) => total + Number(upfsv));
        } else if (
            type === "minus" &&
            cartData[packageId] &&
            cartData[packageId]?.qty > 0
        ) {
            if (cartData[packageId]?.qty === 1) {
                let tempCart = cartData;
                delete tempCart[packageId];
                setCartData(tempCart);
                handleGstCalc();
            } else {
                setCartData((cart) => ({
                    ...cart,
                    [packageId]: {
                        ...product,
                        qty: cart[packageId] ? cart[packageId]?.qty - 1 : 0,
                    },
                }));
            }

            let [cumulativeTotalAmount, cumulativeTotalPv] = [
                totalAmount - Number(mrp),
                totalPv - Number(upfsv),
            ];
            setTotalAmount(cumulativeTotalAmount);
            setTotalPv(cumulativeTotalPv);
        } else if (type === "remove") {
            let [cumulativeTotalAmount, cumulativeTotalPv] = [
                totalAmount - Number(mrp) * cartData[packageId]?.qty,
                totalPv - Number(upfsv) * cartData[packageId]?.qty,
            ];
            setTotalAmount(cumulativeTotalAmount);
            setTotalPv(cumulativeTotalPv);
            let tempCart = cartData;
            delete tempCart[packageId];
            setCartData(tempCart);
            handleGstCalc();
        }
    };

    const handleProceedCheckout = async () => {
        if (totalPv <= 0 || totalPv < upfsvCriteria) {
            toast.error(`Please add more products more.`);
        } else {
            handleNext();
        }
    };
    const handleContinueShopping = () => {
        setActiveStepPr(1);
    };

    return (
        <>
            <div className="cart-checkout">
                <div className="cart-checkout-table">
                    <div className="cart-table">
                        <div className="cart-table-header">
                            <div className="cart-table-header-cell cell-flex-3 ">
                                PRODUCTS
                            </div>
                            <div className="cart-table-header-cell ">QTY</div>
                            <div className="cart-table-header-cell ">PRICE</div>
                            <div className="cart-table-header-cell ">
                                NET AMT
                            </div>
                            <div className="cart-table-header-cell ">GST</div>
                            <div className="cart-table-header-cell ">AMT</div>
                            <div className="cart-table-header-cell ">
                                REMOVE
                            </div>
                        </div>
                        {Object.entries(cartData)?.length > 0 &&
                            Object.entries(cartData)?.map((item) => (
                                <div className="cart-table-body">
                                    <div className="cart-table-body-cell cell-flex-3 ">
                                        <div style={{ display: "flex" }}>
                                            <img
                                                src={item[1]?.image}
                                                alt=""
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "10px",
                                                    marginRight: "20px",
                                                }}
                                            />
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <article
                                                    style={{
                                                        fontSize: "20px",
                                                        wordBreak: "break-word",
                                                        wordBreak: "break-word",
                                                        whiteSpace: "normal",
                                                    }}
                                                >
                                                    {item[1]?.name}
                                                </article>
                                                <article>
                                                    Item Code:{" "}
                                                    {item[1]?.productId}
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cart-table-body-cell">
                                        <div className="cart-cell-qty-up">
                                            <button
                                                id="minus"
                                                onClick={(e) => {
                                                    handleQuantityChange(
                                                        item[1],
                                                        "minus"
                                                    );
                                                }}
                                            >
                                                -
                                            </button>
                                            {item[1]?.qty}
                                            <button
                                                id="plus"
                                                onClick={(e) => {
                                                    handleQuantityChange(
                                                        item[1],
                                                        "plus"
                                                    );
                                                }}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item[1].mrp,
                                            conversionFactor?.value
                                        )}`}
                                    </div>{" "}
                                    <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item[1].mrp * item[1].qty,
                                            conversionFactor?.value
                                        )}`}
                                    </div>{" "}
                                    <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item[1].mrp * item[1].qty * gst,
                                            conversionFactor?.value
                                        )}`}
                                    </div>
                                    <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item[1].mrp *
                                                item[1].qty *
                                                (1 + gst),
                                            conversionFactor?.value
                                        )}`}
                                    </div>
                                    <div
                                        className="cart-table-body-cell"
                                        style={{ alignItems: "center" }}
                                    >
                                        <button
                                            className="cart-table-remove-btn"
                                            onClick={(e) => {
                                                handleQuantityChange(
                                                    item[1],
                                                    "remove"
                                                );
                                            }}
                                        >
                                            <i
                                                className="fa-solid fa-xmark"
                                                style={{ color: "#8c5331" }}
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className="cart-checkout-total-container">
                    <div className="cart-checkout-total-section">
                        <div>SUB TOTAL</div>
                        <div>
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                Number(totalAmount) * (1 + gst),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                    <div className="cart-checkout-total-section">
                        <div>SHIPPING</div>
                        <div>
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                Number(shippingCharge),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                    <div className="cart-checkout-total-section">
                        <div>TOTAL</div>
                        <div>
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                Number(totalAmount) * (1 + gst) +
                                    Number(shippingCharge),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                </div>

                <div className="cart-checkout-button-container">
                    <button
                        id="addMoreBtn"
                        onClick={handleContinueShopping}
                        className="addMoreBtn"
                    >
                        CONTINUE SHOPPING
                    </button>
                    <button onClick={handleProceedCheckout}>
                        PROCEED CHECKOUT
                    </button>
                </div>
            </div>
        </>
    );
};

export default UpgradeCheckoutPackage;
