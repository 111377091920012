import { ReplicaService } from "../../services/replica/replica";

export const getApiKey = async (adminUsername) => {
    try {
        const response = await ReplicaService.getApiKey(adminUsername);
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaHome = async () => {
    try {
        const response = await ReplicaService.getReplicaHome();
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaRegisterFields = async () => {
    try {
        const response = await ReplicaService.getReplicaRegister();
        return response;
    } catch (error) {
        return error.message;
    }
};

export const replicaFieldCheck = async (field, value) => {
    try {
        const response = await ReplicaService.getFieldCheck(field, value);
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaBankUploadReceipt = async (
    data,
    username,
    referralId,
    type
) => {
    try {
        const response = await ReplicaService.callBankUpload(
            data,
            username,
            referralId,
            type
        );
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaBankRecieptDelete = async (data) => {
    try {
        const response = await ReplicaService.ReplicaBankRecieptDelete(data);
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaRegisterPost = async (data) => {
    try {
        const response = await ReplicaService.CallReplicaRegister(data);
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaContactUpload = async (data) => {
    try {
        const response = await ReplicaService.replicaContactUpload(data);
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaCreateStripeIntent = async (data) => {
    try {
        const response = await ReplicaService.replicaCreateStripePaymentIntent(
            data
        );
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaUpdateStripeIntent = async (data) => {
    try {
        const response = await ReplicaService.replicaUpdateStripePaymentIntent(
            data
        );
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaCancelStripeIntent = async (data) => {
    try {
        const response = await ReplicaService.replicaCancelStripePaymentIntent(
            data
        );
        return response;
    } catch (error) {
        return error.message;
    }
};

export const ReplicaTranssPassCheck = async (
    value,
    totalAmount,
    walletType,
    username
) => {
    try {
        const body = {
            transPassword: value,
            totalAmount: totalAmount,
            walletType: walletType,
            username,
        };
        const response = await ReplicaService.callReplicaTransPassCheck(body);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const GetEsignEmbedLink = async (username, email) => {
    try {
        const response = await ReplicaService.getEsignEmbedLink(
            username,
            email
        );
        return response;
    } catch (error) {
        return error.message;
    }
};

export const GetVerifyEsign = async (username, email) => {
    try {
        const response = await ReplicaService.getEsignVerifyStatus(
            username,
            email
        );
        return response;
    } catch (error) {
        return error.message;
    }
};
