import React, { useEffect, useState } from "react";
import ReplicaRegisterForm from "../../components/Replica/ReplicaRegisterForm";
import ReplicaRegisterModal from "../../components/Common/modals/ReplicaRegisterModal";
import anime from "animejs/lib/anime.es.js";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import IbeVerificationFormIn from "../../components/ReplicaRegister/IbeVerificationForm";
import { useForm } from "react-hook-form";
import IbeSignupFormIn from "../../components/ReplicaRegister/IbeSignupForm";
import IbeCountrySelection from "../../components/ReplicaRegister/IbeCountrySelection";
import IbeCheckoutLayout from "../../components/ReplicaRegister/IbeRegisterCheckOut";
import IbeRegisterCart from "../../components/ReplicaRegister/IbeRegisterCart";
import { useParams } from "react-router";
import Item from "antd/es/list/Item";

const ReplicaRegisterLayout = () => {
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const { t } = useTranslation();
    const params = useParams();
    if (params.username) {
        localStorage.setItem("referralId", params.username);
    }

    const {
        register,
        setValue,
        trigger,
        watch,
        setError,
        getValues,
        formState: { errors },
    } = useForm();
    const formValues = watch();

    const handleFormValueChange = (value, type, options = {}) => {
        setValue(`${type}`, value, options);
        setError(`${type}`, { message: "" });
    };

    const [cartData, setCartData] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalPv, setTotalPv] = useState(0);
    const [gst, setGst] = useState(0);
    // ----------------------------------------- API --------------------------------------------
    const registerFields1 = ApiHook.CallReplicaRegisterFields();

    const productPackFilter = (products) => {
        return products?.filter((item) => {
            return (
                item?.countryName?.toLowerCase() ===
                formValues?.country?.toLowerCase()
            );
        });
    };

    const handleGstCalc = () => {
        if (Object.keys(cartData)?.length !== 0) {
            var tempGst = 0;

            Object.entries(cartData)?.map((item) => {
                if (tempGst < item[1]?.gst * 0.01 && item[1]?.qty > 0) {
                    tempGst = item[1]?.gst * 0.01;
                }
            });
            setGst(tempGst);
        }
    };

    console.log(cartData, totalAmount, totalPv, "======================");
    return (
        <>
            <div
                className="container-bg-design-container-flex"
                style={
                    activeStep === 4
                        ? { height: "100vh", backgroundColor: "#fff" }
                        : { height: "100vh" }
                }
            >
                <div
                    className="container-bg-design"
                    style={{ backgroundColor: activeStep === 4 && "#fff" }}
                >
                    <div className="referal-register-container-flex">
                        <div className="referal-register-container">
                            {activeStep === 1 &&
                                registerFields1?.data?.data && (
                                    <IbeCountrySelection
                                        formValues={formValues}
                                        registerFields={
                                            registerFields1?.data?.data
                                        }
                                        handleFormValueChange={
                                            handleFormValueChange
                                        }
                                        setActiveStep={setActiveStep}
                                    />
                                )}
                            {activeStep === 2 && (
                                <IbeVerificationFormIn
                                    register={register}
                                    setError={setError}
                                    errors={errors}
                                    formValues={formValues}
                                    registerFields={registerFields1?.data?.data}
                                    handleFormValueChange={
                                        handleFormValueChange
                                    }
                                    setActiveStep={setActiveStep}
                                />
                            )}
                            {activeStep === 3 && (
                                <IbeSignupFormIn
                                    register={register}
                                    trigger={trigger}
                                    setError={setError}
                                    errors={errors}
                                    formValues={formValues}
                                    registerFields={registerFields1?.data?.data}
                                    handleFormValueChange={
                                        handleFormValueChange
                                    }
                                    setActiveStep={setActiveStep}
                                />
                            )}

                            {activeStep === 4 && (
                                <IbeRegisterCart
                                    formValues={formValues}
                                    products={productPackFilter(
                                        registerFields1?.data?.data?.regPack
                                    )}
                                    setActiveStep={setActiveStep}
                                    activeCurrencies={
                                        registerFields1?.data?.data
                                            ?.activeCurrencies
                                    }
                                    fsvCriteria={
                                        registerFields1?.data?.data?.fsvCriteria
                                    }
                                    cartData={cartData}
                                    setCartData={setCartData}
                                    setTotalAmount={setTotalAmount}
                                    setTotalPv={setTotalPv}
                                    totalAmount={totalAmount}
                                    totalPv={totalPv}
                                    handleGstCalc={handleGstCalc}
                                />
                            )}
                            {activeStep === 5 && (
                                <IbeCheckoutLayout
                                    setValue={setValue}
                                    formValues={formValues}
                                    products={
                                        registerFields1?.data?.data?.regPack
                                    }
                                    handleFormValueChange={
                                        handleFormValueChange
                                    }
                                    setActiveStepPr={setActiveStep}
                                    activeCurrencies={
                                        registerFields1?.data?.data
                                            ?.activeCurrencies
                                    }
                                    fsvCriteria={
                                        registerFields1?.data?.data?.fsvCriteria
                                    }
                                    cartData={cartData}
                                    setCartData={setCartData}
                                    setTotalAmount={setTotalAmount}
                                    setTotalPv={setTotalPv}
                                    totalAmount={totalAmount}
                                    totalPv={totalPv}
                                    registerFields={registerFields1?.data?.data}
                                    gst={gst}
                                    handleGstCalc={handleGstCalc}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReplicaRegisterLayout;
