import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckoutPayment from "./EventPayment";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../hooks/apiHook";
import CommonInput from "../../components/Common/CommonInputComponent";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import EventDetails from "./EventDetails";
const EventList = ({
  setTotalAmount,
  totalAmount,
  userCountry,
  userData,
  conversionFactor,
  balance,
  eventList,
  events,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [transPass, setTransPass] = useState("");
  const [selectedEvent, SetselectedEvent] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(2);
  const [errors , setErrors] =useState(null)
  // ----------------------------------- API -------------------------------------
  const eventPurchaseMutation = ApiHook.CallEventPurchaseMutation();

  const handleSubmit = async () => {
    eventPurchaseMutation.mutate(
      {
        selectedEvent,
        totalAmount,
        paymentMethod,
        transactionPassword: transPass,
      },
      {
        onSuccess: (res) => {
          if (res.status) {
            setTotalAmount(0);
            setPaymentMethod(2);
            setTransPass("");
            SetselectedEvent(null);
            setActiveStep(1);
            setErrors(null) ;
          }
        },
      }
    );
  };

  const handleNext = async () => {
    if (!selectedEvent) {
      setErrors((prev) => ({
        ...prev,
        event: "Event is required"
      }));
      return; 
    }
    if (totalAmount === 0) {
      await handleSubmit(); 
    } else {
      if (userCountry.toLowerCase() === "bangladesh") {
        setPaymentMethod(2);
        const nextStep = activeStep + 1;
        animateStepTransition(nextStep); 
        setActiveStep(nextStep);
      } else {
        setPaymentMethod(3);
        await handleSubmit(); 
      }
    }
  };
  
  const handleBack = () => {
    const prevStep = activeStep - 1;
    animateStepTransition(prevStep);
    setActiveStep(prevStep);
  };
  const animateStepTransition = () => {
    const containerElement = document.querySelector("#animation");
    // Set initial position of the next step
    anime.set(containerElement, { opacity: 0 });
    // Animate the next step in
    anime({
      targets: containerElement,
      opacity: [0, 1],
      duration: 1100,
      easing: "easeInOutQuad",
    });
  };
  return (
    <>
      <div className="common-div-header gta-b">
        {/* {activeStep === 1 ? "CART CHECKOUT" : "CART PAYMENT"} */}
      </div>
      <div className="checkout_contant_area_section" style={{minHeight:"auto"}}>
        <div className="checkout_contant_cart_sec">
          <div id="animation">
            {activeStep === 1 && (
              <EventDetails
                setActiveStep={setActiveStep}
                handleSubmit={handleNext}
                eventList={eventList}
                events={events}
                selectedEvent={selectedEvent}
                SetselectedEvent={SetselectedEvent}
                conversionFactor={conversionFactor}
                balance={balance}
                setTotalAmount={setTotalAmount}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {activeStep === 2 && (
              <CheckoutPayment
                setActiveStep={setActiveStep}
                userData={userData}
                totalAmount={totalAmount}
                conversionFactor={conversionFactor}
                handleSubmit={handleSubmit}
                transPass={transPass}
                setTransPass={setTransPass}
                balance={balance}
                paymentMethod={paymentMethod}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventList;
