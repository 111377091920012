import React, { useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./profile.css";
import PersonalDetails from "../../components/Profile/PersonalDetails.jsx";
import BankDetailsNew from "../../components/Profile/BankDetailsNew.jsx";
import UserProfileAvatar from "../../components/Profile/UserProfileAvatar.jsx";

const ProfileLayout = () => {
    const { t } = useTranslation();
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showTransPasswordModal, setShowTransPasswordModal] = useState(false);
    const [showRankView, setShowRankView] = useState(false);

    const Profile = ApiHook.CallProfile();

    const handleClosePasswordModal = () => {
        setShowPasswordModal(!showPasswordModal);
    };
    const handleCloseTransPasswordModal = () => {
        setShowTransPasswordModal(!showTransPasswordModal);
    };
    const handleCloseRankView = () => {
        setShowRankView(!showRankView);
    };

    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );

    return (
        <div className="container-bg-design">
            <div className="container-bg-design-container-flex ">
                <div className="container-bg-design-container">
                    {/* <div className="" style={{ width: "100%" }}> */}
                    <div className="profile_sec ">
                        <div className="profile_sec-info">
                            <h1 className="common-div-header gta-b">
                                My Profile
                            </h1>
                            {/* <img
                                className="rounded-circle mb-3"
                                style={{ width: "10rem", height: "10rem" }}
                                src={Profile?.data?.profile?.avatar}
                                alt=""
                            /> */}
                            <UserProfileAvatar
                                profile={Profile?.data?.profile}
                            />
                            <h3 style={{ fontSize: "1.8rem" }}>
                                HI,{" "}
                                {Profile?.data?.profile?.fullName?.toUpperCase()}
                            </h3>
                        </div>
                        <div className="profile_sec-details">
                            {Profile?.data?.personalDetails && (
                                <PersonalDetails
                                    profileDetails={
                                        Profile?.data?.personalDetails || {}
                                    }
                                    countries={Profile?.data?.countries}
                                />
                            )}
                            {Profile?.data?.bankDetails && (
                                <BankDetailsNew
                                    bankDetails={
                                        Profile?.data?.bankDetails || {}
                                    }
                                />
                            )}
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
};

export default ProfileLayout;
