import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { exportToExcelNew } from "../../utils/tableExports";
import CommonInput from "../../components/Common/CommonInputComponent";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { isANumber, isMaxLength } from "../../utils/validationHealpers";
import IbeMbcOrderInvoice from "./IbeMbcOrderInvoice";
const IbeMbcDeliveryForm = () => {
    const { t } = useTranslation();

    const {
        register,
        setValue,
        trigger,
        watch,
        setError,
        getValues,
        formState: { errors },
        reset,
    } = useForm();
    const formValues = watch();

    const [orderStatus, setOrderStatus] = useState(false);
    const [otpSendStatus, setOtpSendStatus] = useState(false);
    const [showInvoice, setShowInvoice] = useState(false);

    const getDeliveryOrderSearch = ApiHook.GetDeliveryOrderSearch();
    const sendDeliveryOtp = ApiHook.PostDeliveryOtp();
    const deliverOrder = ApiHook.DeliveryOrder();
    const handleSubmit = async () => {
        if (formValues?.searchOrd && formValues?.searchOrd !== "") {
            getDeliveryOrderSearch.mutate(formValues?.searchOrd, {
                onSuccess: (res) => {
                    if (res.status) {
                        setOrderStatus(true);
                        setValue("recieverIbe", res?.data?.recieverIbeNo);
                        setValue("recieverName", res?.data?.recieverUsername);
                        setValue("recieverUserId", res?.data?.recieverUserId);
                        setValue("orderId", res?.data?.orderId);
                        setError("searchOrd", {
                            message: "",
                        });
                    } else {
                        // toast.error(res?.data?.description);
                        setOrderStatus(false);
                        setError("searchOrd", {
                            message: res?.data?.description,
                        });
                    }
                },
            });
        }
    };

    const handleSendOtp = async () => {
        sendDeliveryOtp.mutate(
            {
                orderId: formValues?.orderId,
                userId: formValues?.recieverUserId,
            },
            {
                onSuccess: (res) => {
                    if (res?.status) {
                        setOtpSendStatus(true);
                    } else {
                        toast.error(t(res?.data?.description));
                    }
                },
            }
        );
    };

    const handleDeliver = async () => {
        const isValid = await trigger("otp");
        if (orderStatus && isValid) {
            deliverOrder.mutate(formValues, {
                onSuccess: (res) => {
                    if (res?.status) {
                        setOrderStatus(false);
                        setOtpSendStatus(false);
                        reset({
                            searchOrd: "",
                            recieverIbe: "",
                            recieverName: "",
                            orderId: "",
                            otp: "",
                            remarks: "",
                        });
                    } else {
                        setError("otp", {
                            message: "Invalid OTP",
                        });
                    }
                },
            });
        } else {
            setError("otp", { message: "Invalid OTP" });
        }
    };

    const handleOpenInvoice = async () => {
        setShowInvoice(true);
    };

    return (
        <>
            <div
                className="report-Container"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div className="report-header common-div-header">
                    IBE MBC DELIVERY
                </div>
                <div className="SP-deliver-form-container">
                    <CommonInput
                        label={"Search ORD #"}
                        value={formValues?.searchOrd}
                        backgroundColor={"#e5f0f2"}
                        formRegister={register("searchOrd", {
                            required: "searchOrd is required",
                        })}
                        // disabled={orderStatus}
                    />
                    {errors?.searchOrd?.message !== "" && (
                        <span className="error-message-validator">
                            {errors?.searchOrd?.message}
                        </span>
                    )}
                    <button
                        className="common-button"
                        onClick={handleSubmit}
                        disabled={
                            getDeliveryOrderSearch.status === "loading" ||
                            orderStatus
                        }
                    >
                        {getDeliveryOrderSearch.status === "loading"
                            ? "SEARCHING..."
                            : "SUBMIT"}
                    </button>

                    {orderStatus && getDeliveryOrderSearch?.data && (
                        <>
                            <span
                                style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}
                            >
                                {
                                    getDeliveryOrderSearch?.data?.data
                                        ?.mbcUsername
                                }
                            </span>
                            <span
                                style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    marginBottom: "20px",
                                }}
                            >
                                {`IBE # ${getDeliveryOrderSearch?.data?.data?.mbcUsername}`}
                            </span>

                            <button
                                className="common-button"
                                onClick={handleOpenInvoice}
                            >
                                VIEW INVOICE
                            </button>

                            <CommonInput
                                label={"Reciever IBE #"}
                                value={formValues?.recieverIbe}
                                backgroundColor={"#e5f0f2"}
                                // formRegister={register("recieverIbe", {
                                //     required: "recieverIbe is required",
                                // })}
                                disabled={true}
                            />
                            <CommonInput
                                label={"Reciever Name #"}
                                value={formValues?.recieverName}
                                backgroundColor={"#e5f0f2"}
                                formRegister={register("recieverName", {
                                    required: "recieverName is required",
                                })}
                                disabled={true}
                            />
                            <CommonInput
                                label={"OTP"}
                                value={formValues?.otp}
                                backgroundColor={"#e5f0f2"}
                                formRegister={register("otp", {
                                    required: "OTP is required",
                                    minLength: {
                                        value: 6,
                                        message: "Invalid OTP",
                                    },
                                })}
                                onChange={(e) => {
                                    if (
                                        isANumber(e.target.value) &&
                                        isMaxLength(e.target.value, 6)
                                    ) {
                                        setValue("otp", e.target.value);
                                        setError("otp", { message: "" });
                                    }
                                }}
                            />
                            {errors?.otp?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.otp?.message}
                                </span>
                            )}

                            <button
                                className="SP-deliver-form-otp-btn"
                                onClick={handleSendOtp}
                            >
                                {!otpSendStatus &&
                                    (sendDeliveryOtp?.status === "loading"
                                        ? "Sending"
                                        : "Send OTP")}
                                {otpSendStatus && "Resend OTP"}
                            </button>

                            <CommonInput
                                label={"Remarks"}
                                value={formValues?.remarks}
                                backgroundColor={"#e5f0f2"}
                                formRegister={register("remarks", {
                                    required: "OTP is required",
                                })}
                            />

                            <button
                                className="common-button"
                                onClick={handleDeliver}
                            >
                                DELIVER
                            </button>
                        </>
                    )}
                </div>
            </div>

            <IbeMbcOrderInvoice
                show={showInvoice}
                setShow={setShowInvoice}
                invoice={formValues?.searchOrd}
                purchaseType={getDeliveryOrderSearch?.data?.data?.orderType}
            />
        </>
    );
};

export default IbeMbcDeliveryForm;
