import React, { useState } from "react";
import "./dash.css";
import {
  BarChart,
  BarCommissionChart,
  DoughnutChart,
  LineChart,
} from "../components/Common/charts/charts";
import DashboardService from "../services/dashboard/Dashboard";
import { ApiHook } from "../hooks/apiHook";

const DashLayout = () => {
  const appLayout = ApiHook.CallAppLayout();

  const [dashboardCheck, setDashboardCheck] = useState(true);

  const rightSection = ApiHook.CallDashboardRight(
    dashboardCheck,
    setDashboardCheck
  );
  const { data: RankChartData } = DashboardService.GetRankChart();

  const rankLabels = RankChartData?.rankLabel?.map((item) => `Week ${item}`);

  return (
    <div>
      {/* Tiles */}
      <div className="dash-tile">
        <div className="d-flex flex-column text-center  justify-content-center ">
          <div>
            <div >Join Date</div>
            <div className="member-text">
              {" "}
              {rightSection?.data?.userProfile?.dateOfJoin}
            </div>
          </div>
          <div className="mt-3">
            <div>Last Order Date</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.lastOrderDate}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column text-center  justify-content-center">
          <div>
            <div>Live Rank</div>
            <div className="member-text">
              {" "}
              {rightSection?.data?.userProfile?.rankName ?? "NA"}
            </div>
          </div>
          <div className="mt-3">
            <div>Status</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.status}{" "}
              {rightSection?.data?.userProfile?.status === "Active" && (
                <i
                  class="fa-solid fa-circle-check"
                  style={{ color: "#749842" }}
                ></i>
              )}
              {rightSection?.data?.userProfile?.status === "Inactive" && (
                <i
                  class="fa-solid fa-circle-xmark"
                  style={{ color: "#c91a1a" }}
                ></i>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column text-center align-items-center  justify-content-center">
          <div>
            <div>Highest Rank</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.highestRank ?? "NA"}
            </div>
          </div>
          <div className="mt-3">
            <div>Training Score</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.ts ?? 0}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column text-center align-items-center  justify-content-center">
          <div>
            <div>PSV</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.psv}
            </div>
          </div>
          <div className="mt-3">
            <div>QSV</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.qsv}
            </div>
          </div>
          <div className="mt-3">
            <div>RSV</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.rsv}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column text-center align-items-center  justify-content-center">
          <div>
            <div>Week #</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.week}
            </div>
          </div>
          <div className="mt-3">
            <div>Level</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.packageName}
            </div>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="row">
        <div className="mt-4 col-xl-6 col-md-12 col-sm-12">
          <div className="dash-charts">
            <div className="d-md-flex d-block text-md-start text-center gap-1 align-items-center justify-content-between mb-4">
              <h4 className="mb-0">LTSV/RTSV</h4>
              <div className="d-flex gap-2 align-items-center my-md-0 my-3 justify-content-center justify-content-md-start">
                <div className="d-flex align-items-center gap-1">
                  <div className="ltsv-label"></div> <div>LTSV</div>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <div className="rtsv-label"></div> <div>RTSV</div>
                </div>
              </div>
              <div className="d-flex justify-content-center justify-content-md-start">
              <div className="tsv-data-filter button_week_change text-md-start ">Weekly</div>
              </div>
            </div>
            {rightSection?.data && (
              <BarChart
                labels={rightSection?.data?.labels}
                datas={rightSection?.data?.teamCommissionData}
              />
            )}
          </div>
        </div>
        <div className="mt-4 col-xl-6 col-md-12 col-sm-12">
          <div className="dash-charts">
            <div className="d-md-flex d-block text-md-start text-center gap-1 align-items-center justify-content-between mb-4">
              <h4 className="mb-0">Rank</h4>
              <div className="d-flex justify-content-center justify-content-md-start mt-3">
              <div className="tsv-data-filter button_week_change text-md-start">Weekly</div>
              </div>
            </div>

            <LineChart data={RankChartData?.rankValue} labels={rankLabels} />
          </div>
        </div>
      </div>
      <div className="row last_chart_pb_sm">
        <div className="mt-4 col-xl-6 col-md-12 col-sm-12">
          <div className="dash-charts h-100" style={{"display":"grid","alignItems":"start"}}>
            <h4 className="mb-4">Total Team Count</h4>
            <div className="row">
              <div className="col-xxl-7 col-xl-7 col-md-6" >
                <div className="d-flex justify-content-center" style={{ maxWidth: "100%" }}>
                  {rightSection?.data && (
                    <DoughnutChart
                      labels={["Active", "Inactive", "Expired", "Terminated"]}
                      data={rightSection?.data?.doughnutData}
                    />
                  )}
                </div>
              </div>
              <div className="col-xxl-5 col-xl-5 col-md-6 d-flex align-items-center">
                <div className="row">
                  <div className="row justify-content-between mt-4 mt-md-0" style={{"rowGap":"12px"}}>
                    <div className="col-xxl-12 col-auto p-0">
                      <div className="d-flex gap-2 align-items-center">
                        <div
                          className="doghnut-label-dot"
                          style={{ background: "#904B21" }}
                        ></div>
                        <div>Active</div>
                        <div style={{ fontSize: "1.2em" }}>
                          {rightSection?.data?.teamUserStatuscount?.active}%
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-auto p-0">
                      <div className="d-flex gap-2 align-items-center">
                        <div
                          className="doghnut-label-dot"
                          style={{ background: "#C8CFB7" }}
                        ></div>
                        <div>Inactive</div>
                        <div style={{ fontSize: "1.2em" }}>
                          {rightSection?.data?.teamUserStatuscount?.inactive}%
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-auto p-0">
                      <div className="d-flex gap-2 align-items-center">
                        <div
                          className="doghnut-label-dot"
                          style={{ background: "#749842" }}
                        ></div>
                        <div>Expired</div>
                        <div style={{ fontSize: "1.2em" }}>
                          {rightSection?.data?.teamUserStatuscount?.expired}%
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-auto p-0">
                      <div className="d-flex gap-2 align-items-center">
                        <div
                          className="doghnut-label-dot"
                          style={{ background: "#2E8CA5" }}
                        ></div>
                        <div>Terminated</div>
                        <div style={{ fontSize: "1.2em" }}>
                          {rightSection?.data?.teamUserStatuscount?.terminated}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex flex-column gap-4 w-100 ps-5">
                                <div className="d-flex gap-2 align-items-center">
                                    <div
                                        className="doghnut-label-dot"
                                        style={{ background: "#904B21" }}
                                    ></div>
                                    <div>Active</div>
                                    <div style={{ fontSize: "1.2em" }}>60%</div>
                                </div>
                                <div className="d-flex gap-2 align-items-center">
                                    <div
                                        className="doghnut-label-dot"
                                        style={{ background: "#C8CFB7" }}
                                    ></div>
                                    <div>Inactive</div>
                                    <div style={{ fontSize: "1.2em" }}>
                                        12.5%
                                    </div>
                                </div>
                                <div className="d-flex gap-2 align-items-center">
                                    <div
                                        className="doghnut-label-dot"
                                        style={{ background: "#749842" }}
                                    ></div>
                                    <div>Expired</div>
                                    <div style={{ fontSize: "1.2em" }}>
                                        17.5%
                                    </div>
                                </div>
                                <div className="d-flex gap-2 align-items-center">
                                    <div
                                        className="doghnut-label-dot"
                                        style={{ background: "#2E8CA5" }}
                                    ></div>
                                    <div>Terminated</div>
                                    <div style={{ fontSize: "1.2em" }}>10%</div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="mt-4 col-xl-6 col-md-12 col-sm-12">
          <div className="dash-charts h-100 d-grid align-content-between">
            <div className="d-md-flex d-block text-md-start text-center gap-1 align-items-center justify-content-between mb-4">
              <h4 className="mb-0">Commission</h4>
              <div className="d-flex justify-content-center justify-content-md-start mt-3">
              <div className="tsv-data-filter">Weekly</div>
              </div>
            </div>
            <BarCommissionChart
              labels={rightSection?.data?.labels}
              datas={rightSection?.data?.commissionData}
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default DashLayout;
