import React, { useState } from "react";
import CommonInput from "../Common/CommonInputComponent";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import { ApiHook } from "../../hooks/apiHook";
import {
    isANumber,
    isAlphaOnly,
    isAlphanumeric,
    isMaxLength,
    isStringWithoutSpaces,
} from "../../utils/validationHealpers";
import moment from "moment";

const IbeSignupFormIn = ({
    register,
    trigger,
    setError,
    errors,
    formValues,
    registerFields,
    handleFormValueChange,
    setActiveStep,
}) => {
    const backgroundColor = "#e5f0f2";
    const checkEmailField = ApiHook.CallReplicaFieldCheck();
    const checkUsernameField = ApiHook.CallReplicaFieldCheck();

    const countryList = (data) => {
        return data.map((item) => item?.name);
    };

    const ibeIndianReg =
        formValues.country.toLowerCase() === "india" ? true : false;
    const stateList = (data) => {
        if (!formValues?.country) {
            return []; // Return an empty array if there's no country specified.
        }

        const selectedCountry = data.find(
            (country) => country.name === formValues.country
        );
        if (!selectedCountry) {
            return []; // Return an empty array if the selected country is not found.
        }

        return selectedCountry.States.map((state) => state?.name);
    };
    const phoneCodeList = (data) => {
        return data.map((item) => item?.phoneCode);
    };

    const handleEmailValidation = () => {
        checkEmailField.mutate(
            { field: "email", value: formValues?.email },
            {
                onSuccess: (res) => {
                    if (!res.status) {
                        if (res?.data?.code === 1148) {
                            setError("email", {
                                message: res?.data?.description,
                            });
                        } else {
                            setError("email", {
                                message: "Email already registered",
                            });
                        }
                    }
                    return res;
                },
            }
        );
    };

    const handleUsernameValidation = () => {
        checkUsernameField.mutate(
            { field: "username", value: formValues?.username },
            {
                onSuccess: (res) => {
                    if (!res.status) {
                        setError("username", {
                            message: "Username already registered",
                        });
                    }
                },
            }
        );
    };

    const handleSubmit = async () => {
        const isValid = await trigger();
        if (
            isValid &&
            checkEmailField?.data?.data?.status &&
            checkUsernameField?.data?.data?.status
        ) {
            setActiveStep(4);
        } else if (!checkEmailField?.data?.data?.status) {
            // setError("email", {
            //     message: "Email already registered",
            // });
            handleEmailValidation();
            if (checkEmailField?.data?.data?.status) {
                handleSubmit();
            }
        } else if (!checkUsernameField?.data?.data?.status) {
            handleUsernameValidation();
            if (checkUsernameField?.data?.data?.status) {
                handleSubmit();
            }
        }
    };
    const validateAge = (value) => {
        const today = new Date();
        const birthDate = new Date(value);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
            age--;
        }

        return age >= 18 || "You must be at least 18 years old";
    };

    return (
        <>
            <div className="register-signup-container">
                <div className="common-div-header gta-b">
                    BRAND EXECUTIVE SIGNUP FORM
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="row gx-4" style={{ width: "100%" }}>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                label="Sponsor IBE"
                                placeholder="00000000000"
                                backgroundColor={backgroundColor}
                                value={registerFields?.sponsorData?.ibeNo}
                                // onChange={(e) => {
                                //     handleFormValueChange(
                                //         e,
                                //         "sponsor_username"
                                //     );
                                // }}
                                disabled={true}
                            />
                            {errors?.sponsor_ibe?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.sponsor_ibe?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                label="Sponser Name"
                                backgroundColor={backgroundColor}
                                value={registerFields?.sponsorData?.username}
                                // onChange={(e) => {
                                //     handleFormValueChange(
                                //         e.target.value,
                                //         "sponser_name"
                                //     );
                                // }}
                                disabled={true}
                            />
                            {errors?.sponsor_username?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.sponsor_username?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="select"
                                label="Placement L/R"
                                backgroundColor={backgroundColor}
                                value={formValues?.placement}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "placement"
                                    );
                                }}
                                options={["left", "right"]}
                                defaultValue=""
                                formRegister={register("placement", {
                                    required: "Placement is required",
                                })}
                            />
                            {errors?.placement?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.placement?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                label="Father Name/Surname"
                                backgroundColor={backgroundColor}
                                value={formValues?.surname}
                                onChange={(e) => {
                                    if (
                                        isAlphaOnly(e.target.value) &&
                                        isMaxLength(e.target.value, 25)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "surname"
                                        );
                                    }
                                }}
                                formRegister={register("surname", {
                                    required: "Father Name/Surname is required",
                                    minLength: {
                                        value: 3,
                                        message:
                                            "Surname should be greater than 3",
                                    },
                                })}
                            />
                            {errors?.surname?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.surname?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                label="First Name"
                                placeholder={"Your First Name"}
                                backgroundColor={backgroundColor}
                                value={formValues?.firstname}
                                onChange={(e) => {
                                    if (
                                        isAlphaOnly(e.target.value) &&
                                        isMaxLength(e.target.value, 25)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "firstname"
                                        );
                                    }
                                }}
                                formRegister={register("firstname", {
                                    required: "First Name is required",
                                    minLength: {
                                        value: 3,
                                        message:
                                            "First Name should be greater than 3",
                                    },
                                })}
                            />
                            {errors?.firstname?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.firstname?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                label="Last Name"
                                placeholder={"Your Last Name"}
                                backgroundColor={backgroundColor}
                                value={formValues?.lastname}
                                onChange={(e) => {
                                    if (
                                        isAlphaOnly(e.target.value) &&
                                        isMaxLength(e.target.value, 25)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "lastname"
                                        );
                                    }
                                }}
                                formRegister={register("lastname", {
                                    required: "Last Name is required",
                                })}
                            />
                            {errors?.lastname?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.lastname?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="select"
                                label="Gender"
                                placeholder="Select..."
                                backgroundColor={backgroundColor}
                                value={formValues?.gender}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "gender"
                                    );
                                }}
                                options={["male", "female", "other"]}
                                defaultValue=""
                                formRegister={register("gender", {
                                    required: "Gender is required",
                                })}
                            />
                            {errors?.gender?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.gender?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="date"
                                label="Date of Birth"
                                placeholder="Select..."
                                backgroundColor={backgroundColor}
                                value={formValues?.dob}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "dob"
                                    );
                                }}
                                // options={["male", "female", "other"]}
                                defaultValue=""
                                formRegister={register("dob", {
                                    required: "Date of Birth is required",
                                    validate: validateAge,
                                })}
                                onBlur={(e) => {
                                    let age = moment().diff(
                                        moment(formValues.dob, "YYYY-MM-DD"),
                                        "years"
                                    );
                                    handleFormValueChange(age, "age");
                                }}
                            />
                            {errors?.dob?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.dob?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="number"
                                label="Age"
                                backgroundColor={backgroundColor}
                                value={formValues?.age}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "age"
                                    );
                                }}
                                formRegister={register("age", {
                                    required: "Age is required",
                                })}
                                disabled={true}
                            />
                            {errors?.age?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.age?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="select"
                                label="Marital Status"
                                placeholder="Select..."
                                backgroundColor={backgroundColor}
                                value={formValues?.maritalStatus}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "maritalStatus"
                                    );
                                }}
                                options={[
                                    "single",
                                    "married",
                                    "divorced",
                                    "widowed",
                                    "seperated",
                                ]}
                                defaultValue=""
                                formRegister={register("maritalStatus", {
                                    required: "Marital Status is required",
                                })}
                            />
                            {errors?.maritalStatus?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.maritalStatus?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="mobile"
                                label="WHATSAPP NUMBER"
                                placeholder={"0000000000000"}
                                backgroundColor={backgroundColor}
                                value={formValues?.waMobile}
                                onChange={(e) => {
                                    if (
                                        isMaxLength(e.target.value, 10) &&
                                        isANumber(e.target.value)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "waMobile"
                                        );
                                    }
                                }}
                                formRegister={register("waMobile", {
                                    required: "Whatsapp No is required",
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message:
                                            "Invalid mobile number. It should be 10 digits.",
                                    },
                                    maxLength: {
                                        value: 10,
                                        message:
                                            "You cannot enter more than 10 characters",
                                    },
                                })}
                                options={phoneCodeList(
                                    registerFields?.contactInformation?.contactField?.filter(
                                        (item) => item?.code === "country"
                                    )[0]?.options ?? [""]
                                )}
                                phoneCodeChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "phoneCode"
                                    );
                                }}
                                phoneCodeDisabled={true}
                                phoneCode={formValues?.phoneCode}
                            />
                            {errors?.waMobile?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.waMobile?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="mobile"
                                label="MOBILE NUMBER"
                                placeholder={"0000000000000"}
                                backgroundColor={backgroundColor}
                                value={formValues?.mobile}
                                onChange={(e) => {
                                    if (
                                        isMaxLength(e.target.value, 10) &&
                                        isANumber(e.target.value)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "mobile"
                                        );
                                    }
                                }}
                                formRegister={register("mobile", {
                                    required: "Mobile No is required",
                                    pattern: {
                                        value: /^[0-9]{10}$/, // Regex for 10-digit mobile number
                                        message:
                                            "Invalid mobile number. It should be 10 digits.",
                                    },
                                })}
                                options={phoneCodeList(
                                    registerFields?.contactInformation?.contactField?.filter(
                                        (item) => item?.code === "country"
                                    )[0]?.options ?? [""]
                                )}
                                phoneCodeChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "phoneCode"
                                    );
                                }}
                                phoneCode={formValues?.phoneCode}
                                disabled={ibeIndianReg}
                                phoneCodeDisabled={true}
                            />
                            {errors?.mobile?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.mobile?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="email"
                                label="Email"
                                placeholder={"Your Email Address"}
                                backgroundColor={backgroundColor}
                                value={formValues?.email}
                                onChange={(e) => {
                                    if (isMaxLength(e.target.value, 40)) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "email"
                                        );
                                    }
                                }}
                                formRegister={register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Invalid email address",
                                    },
                                })}
                                onBlur={handleEmailValidation}
                            />
                            {errors?.email?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.email?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="Pan Number"
                                backgroundColor={backgroundColor}
                                value={formValues?.pan}
                                onChange={(e) => {
                                    if (
                                        isAlphanumeric(e.target.value) &&
                                        isMaxLength(e.target.value, 13)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "pan"
                                        );
                                    }
                                }}
                                formRegister={register("pan", {
                                    required: "Pan Number is required",
                                    minLength: {
                                        value: 5,
                                        message:
                                            "Pan Number should be greater than 5",
                                    },
                                })}
                                disabled={ibeIndianReg}
                            />
                            {errors?.pan?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.pan?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="select"
                                label="Education"
                                backgroundColor={backgroundColor}
                                value={formValues?.education}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "education"
                                    );
                                }}
                                formRegister={register("education", {
                                    required: "Education is required",
                                })}
                                options={[
                                    "High School Diploma / GED",
                                    "Associate Degree",
                                    "Bachelor's Degree",
                                    "Master's Degree",
                                    "Doctoral Degree",
                                    "Other",
                                ]}
                            />
                            {errors?.education?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.education?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="select"
                                label="Occupation"
                                backgroundColor={backgroundColor}
                                value={formValues?.occupation}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "occupation"
                                    );
                                }}
                                formRegister={register("occupation", {
                                    required: "Occupation is required",
                                })}
                                options={["Salaried", "Self-employed", "Other"]}
                            />
                            {errors?.occupation?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.occupation?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="Address1"
                                backgroundColor={backgroundColor}
                                value={formValues?.address1}
                                onChange={(e) => {
                                    if (
                                        isAlphanumeric(e.target.value) &&
                                        isMaxLength(e.target.value, 30)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "address1"
                                        );
                                    }
                                }}
                                formRegister={register("address1", {
                                    required: "Address1 is required",
                                    minLength: {
                                        value: 3,
                                        message:
                                            "Pan Number should be greater than 3",
                                    },
                                })}
                            />
                            {errors?.address1?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.address1?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="Address2"
                                backgroundColor={backgroundColor}
                                value={formValues?.address2}
                                onChange={(e) => {
                                    if (
                                        isAlphanumeric(e.target.value) &&
                                        isMaxLength(e.target.value, 30)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "address2"
                                        );
                                    }
                                }}
                                formRegister={register("address2", {
                                    required: "Address2 is required",
                                    minLength: {
                                        value: 3,
                                        message:
                                            "Pan Number should be greater than 3",
                                    },
                                })}
                            />
                            {errors?.address2?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.address2?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                label="City"
                                backgroundColor={backgroundColor}
                                value={formValues?.city}
                                onChange={(e) => {
                                    if (
                                        isAlphanumeric(e.target.value) &&
                                        isMaxLength(e.target.value, 30)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "city"
                                        );
                                    }
                                }}
                                formRegister={register("city", {
                                    required: "City is required",
                                    minLength: {
                                        value: 3,
                                        message:
                                            "City name should be greater than 3",
                                    },
                                })}
                            />
                            {errors?.city?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.city?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="select"
                                label="Country"
                                backgroundColor={backgroundColor}
                                value={formValues?.country}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "country"
                                    );
                                    handleFormValueChange(
                                        { target: { value: "" } },
                                        "state"
                                    );
                                }}
                                options={countryList(
                                    registerFields?.contactInformation?.contactField?.filter(
                                        (item) => item?.code === "country"
                                    )[0]?.options ?? [""]
                                )}
                                defaultValue=""
                                formRegister={register("country", {
                                    required: "Country is required",
                                })}
                                disabled={true}
                            />
                            {errors?.country?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.country?.message}
                                </span>
                            )}
                        </div>{" "}
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="select"
                                label="State"
                                backgroundColor={backgroundColor}
                                value={formValues?.state}
                                onChange={(e) => {
                                    handleFormValueChange(
                                        e.target.value,
                                        "state"
                                    );
                                }}
                                options={stateList(
                                    registerFields?.contactInformation?.contactField?.filter(
                                        (item) => item?.code === "country"
                                    )[0]?.options ?? [""]
                                )}
                                defaultValue=""
                                formRegister={register("state", {
                                    required: "State is required",
                                })}
                            />
                            {errors?.state?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.state?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="Pin Code"
                                backgroundColor={backgroundColor}
                                value={formValues?.pinCode}
                                onChange={(e) => {
                                    if (
                                        isANumber(e.target.value) &&
                                        isMaxLength(e.target.value, 6)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "pinCode"
                                        );
                                    }
                                }}
                                formRegister={register("pinCode", {
                                    required: "Pin Code is required",
                                    minLength: {
                                        value: 3,
                                        message:
                                            "Pan Number should be greater than 3",
                                    },
                                })}
                            />
                            {errors?.pinCode?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.pinCode?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="Bank Account Name"
                                backgroundColor={backgroundColor}
                                value={formValues?.bankAccountName}
                                onChange={(e) => {
                                    if (
                                        isAlphaOnly(e.target.value) &&
                                        isMaxLength(e.target.value, 30)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "bankAccountName"
                                        );
                                    }
                                }}
                                formRegister={register("bankAccountName", {
                                    required: "Bank Account Name is required",
                                    minLength: {
                                        value: 3,
                                        message:
                                            "Pan Number should be greater than 3",
                                    },
                                })}
                            />
                            {errors?.bankAccountName?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.bankAccountName?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="Bank Name"
                                backgroundColor={backgroundColor}
                                value={formValues?.bankName}
                                onChange={(e) => {
                                    if (
                                        isAlphaOnly(e.target.value) &&
                                        isMaxLength(e.target.value, 30)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "bankName"
                                        );
                                    }
                                }}
                                formRegister={register("bankName", {
                                    required: "Bank Name is required",
                                    minLength: {
                                        value: 3,
                                        message:
                                            "Pan Number should be greater than 3",
                                    },
                                })}
                            />
                            {errors?.bankName?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.bankName?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="Bank Account Number"
                                backgroundColor={backgroundColor}
                                value={formValues?.accountNo}
                                onChange={(e) => {
                                    if (
                                        isANumber(e.target.value) &&
                                        isMaxLength(e.target.value, 22)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "accountNo"
                                        );
                                    }
                                }}
                                // disabled={}

                                formRegister={register("accountNo", {
                                    required: "Bank Account Number is required",
                                    minLength: {
                                        value: 5,
                                        message:
                                            "Pan Number should be greater than 5",
                                    },
                                })}
                                disabled={ibeIndianReg}
                            />
                            {errors?.accountNo?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.accountNo?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="IFSC /Routing /BANK #"
                                placeholder={"Your Bank IFSC Code"}
                                backgroundColor={backgroundColor}
                                value={formValues?.ifsc}
                                onChange={(e) => {
                                    if (
                                        isAlphanumeric(e.target.value) &&
                                        isMaxLength(e.target.value, 18)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "ifsc"
                                        );
                                    }
                                }}
                                formRegister={register("ifsc", {
                                    required:
                                        "IFSC /Routing /BANK # is required",
                                })}
                                disabled={ibeIndianReg}
                            />
                            {errors?.ifsc?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.ifsc?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="USERNAME"
                                placeholder={"Enter a Username"}
                                backgroundColor={backgroundColor}
                                value={formValues?.username}
                                onChange={(e) => {
                                    if (
                                        isAlphanumeric(e.target.value) &&
                                        isMaxLength(e.target.value, 15)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "username"
                                        );
                                    }
                                }}
                                formRegister={register("username", {
                                    required: "Username  required",
                                    minLength: {
                                        value: 4,
                                        message:
                                            "Pan Number should be greater than 5",
                                    },
                                })}
                                onBlur={handleUsernameValidation}
                            />
                            {errors?.username?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.username?.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-12 px-4">
                            <CommonInput
                                type="text"
                                label="PASSWORD"
                                placeholder={"Enter a password"}
                                backgroundColor={backgroundColor}
                                value={formValues?.password}
                                onChange={(e) => {
                                    if (
                                        isStringWithoutSpaces(e.target.value) &&
                                        isMaxLength(e.target.value, 15)
                                    ) {
                                        handleFormValueChange(
                                            e.target.value,
                                            "password"
                                        );
                                    }
                                }}
                                formRegister={register("password", {
                                    required: "Password required",
                                    minLength: {
                                        value: 5,
                                        message:
                                            "Pan Number should be greater than 5",
                                    },
                                    maxLength: {
                                        value: 15,
                                        message:
                                            "Password cannot exceed 15 characters",
                                    },
                                    validate: {
                                        hasAlphabet: (value) =>
                                            /[A-Za-z]/.test(value) ||
                                            "Password must contain at least one alphabet",
                                        hasNumber: (value) =>
                                            /\d/.test(value) ||
                                            "Password must contain at least one number",
                                        noSpaces: (value) =>
                                            !/\s/.test(value) ||
                                            "Password cannot contain spaces",
                                    },
                                })}
                            />
                            {errors?.password?.message !== "" && (
                                <span className="error-message-validator">
                                    {errors?.password?.message}
                                </span>
                            )}
                        </div>
                        <div className="register-signup-submit-btn">
                            <button
                                className="common-button"
                                style={{ maxWidth: "500px" }}
                                onClick={handleSubmit}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeSignupFormIn;
