export const isANumber = (str) => {
    return /^[0-9]+$/.test(str) || str === "";
};
export const isAlphaOnly = (str) => {
    return /^[a-zA-Z ]*$/.test(str) || str === "";
};

export const isAlphanumeric = (str) => {
    return /^[a-zA-Z0-9]*$/.test(str) || str === "";
};

export const isMaxLength = (str, ln = 10) => {
    return str?.length <= ln;
};

export const isStringWithoutSpaces = (str) => {
    return /^[^\s]*$/.test(str) || str === "";
};
