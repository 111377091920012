import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { formatDateWithoutTime } from "../../utils/formateDate";
import CommonInput from "../../components/Common/CommonInputComponent";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { error } from "jquery";
const EventDetails = ({
    setActiveStep,
    handleSubmit,
    eventList,
    events,
    selectedEvent,
    SetselectedEvent,
    conversionFactor,
    balance,
    setTotalAmount,
    errors,
    setErrors,
}) => {
    //   const [eventDetails ,setEventDetails]  =useState(null) ;
    //   const backgroundColor = "#fff";
    const handleChange = async (value) => {
        eventList.map((i) => {
            if (i.eventName === value) {
                SetselectedEvent(i);
                setErrors((prev) => ({
                    ...prev,
                    event: null,
                }));
                const balanceAmt =
                    i["eventsData.amount"] > balance
                        ? i["eventsData.amount"] - balance
                        : balance - i["eventsData.amount"];
                setTotalAmount(balanceAmt);
            }
        });
    };
    return (
        <>
            <div className="cart-checkout">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="register-verification-container">
                        <CommonInput
                            type="text"
                            label="Available Amount"
                            //   backgroundColor={backgroundColor}
                            value={`${
                                conversionFactor?.symbolLeft
                            } ${CustomCurrencyConverter(
                                balance,
                                conversionFactor?.value
                            )}`}
                            defaultValue=""
                            disabled={true}
                        />
                        <CommonInput
                            type="select"
                            label="Events"
                            //   backgroundColor={backgroundColor}
                            value={selectedEvent?.eventName ?? ""}
                            onChange={(e) => {
                                handleChange(e.target.value);
                            }}
                            options={events}
                            defaultValue=""
                            isValidationEnabled={true}
                        />
                        {selectedEvent && (
                            <div>
                                <h3>Event Details</h3>
                                <div
                                    className="cart-checkout-total-section"
                                    style={{ display: "block" }}
                                >
                                    <p>
                                        {" "}
                                        Event Amount :{" "}
                                        {`${
                                            conversionFactor?.symbolLeft
                                        } ${CustomCurrencyConverter(
                                            selectedEvent["eventsData.amount"],
                                            conversionFactor?.value
                                        )}`}
                                    </p>
                                    <p>
                                        {" "}
                                        Event Date :{" "}
                                        {formatDateWithoutTime(
                                            selectedEvent[
                                                "eventsData.eventDate"
                                            ]
                                        )}
                                    </p>
                                    <p>
                                        {" "}
                                        Event Location :{" "}
                                        {
                                            selectedEvent[
                                                "eventsData.eventLocation"
                                            ]
                                        }
                                    </p>
                                </div>
                            </div>
                        )}
                        {errors?.event && <p>{errors.event}</p>}
                        <div
                            className="cart-checkout-button-container"
                            style={{ display: "block" }}
                        >
                            <button
                                onClick={handleSubmit}
                                style={{ width: "auto" }}
                            >
                                PROCEED CHECKOUT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventDetails;
